@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Noto+Serif+SC:wght@200;300;400;500;600;700;900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

@mixin font-sans {
  font-family: 'Source Sans Pro', 'Noto Sans SC', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'PingFang SC',
    'Microsoft YaHei', /* Good luck Linux users */ sans-serif;
}

@mixin font-serif {
  font-family: 'Source Serif Pro', 'Noto Serif SC', serif;
}

@mixin font-monospace {
  font-family: 'Source Code Pro', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
