@import './consts.scss';

.md {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;

  ul {
    list-style-type: 'ฅ ';
    li {
      padding-left: 10px;
    }

    ::marker {
      font-weight: 900;
      color: change-color($accent, $alpha: 0.7);
    }
  }

  ol {
    list-style-type: hiragana;
    li {
      padding-left: 10px;
    }
    ::marker {
      @include font-sans;
      font-size: 0.7em;
      font-weight: 900;
      color: change-color($primary, $alpha: 0.7);
    }
  }

  @for $i from 1 to 7 {
    h#{$i} {
      font-weight: 900;
      break-after: avoid;
      &::first-letter {
        color: $primary;
        font-size: 1.4em;
      }
    }
  }

  pre {
    background: $background;
    padding: 20px;
    border-top: $primary 5px solid;
  }

  a {
    color: $primary;
    text-decoration: underline;
    text-decoration-color: $primary;
  }

  &-img {
    display: block;
    width: min-content;
    text-align: center;
    padding: 15px 15px 5px 15px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 6px;
    margin: 0 auto;

    &-alt {
      font-size: 14px;
      @include font-sans;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  blockquote {
    background: $accent-background;
    margin-left: 0;
    padding: 0 30px;
    border-left: change-color($accent-background, $alpha: 0.5) 5px solid;
  }
}
