$left-spacing: 120px + 10px + 40px + 20px;
$top-spacing: 40px + 120px + 40px;
$col-spacing: 200px;

$col-width: 550px;

$overscroll: hoz-padding($left-spacing);

main.entry {
  margin-top: $top-spacing;
  padding-left: $left-spacing;
  padding-right: $left-spacing;

  height: calc(var(--full-height) - #{80px+$top-spacing});

  // TODO: figure out why does overflow-y here breaks everything
  // overflow-y: hidden;

  @include font-serif;
}

main.entry > div {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$col-spacing;
  writing-mode: vertical-lr;

  > * {
    writing-mode: horizontal-tb;

    margin-right: $col-spacing;
    width: $col-width;
  }

  &::after {
    content: '';
    position: static;
    width: $overscroll;
    height: 20px;
    margin-left: $col-width - 20px;
    border-left: $accent 20px solid;
  }
}

.entry-fade {
  &-enter {
    opacity: 0;
    transform: translateX(30px);
  }

  &-enter-active {
    transition: opacity 0.5s ease, transform 1s ease;
    opacity: 1;
    transform: none;
  }

  &-exit {
    opacity: 1;
    transform: none;
  }

  &-exit-active {
    transition: opacity 0.25s ease, transform 0.25s ease;
    opacity: 0;
    transform: translateX(30px);
    pointer-events: none;
  }
}

// Info fold
.entry .md h1 {
  position: sticky;
  left: calc(var(--full-width) / 2 - #{$extended-width / 2 - $left-spacing});
  margin-top: 20px;
  font-size: 52px;
  line-height: 60px;
  transition: transform 0.2s ease;

  width: $col-width;
}

.entry-folded .md h1 {
  transform: translateY(-125px);
}
