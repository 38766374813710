$hover-shift: 40px;

$pin-transition-length: 1s;

$move-dist: 420px - 120px + 60px - 20px; // TODO: use variable logo width / gap / etc

@mixin right-biased {
  /* prettier-ignore */
  transform: translateX(
    calc(
      /* Bais added by the environment (pinned or free) */
      var(--right-bias)

      /* Early transition */
      - var(--home-pin-early)

      /* Late transition */
      - var(--home-pin-late)
    )
  );
}

.home {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  pointer-events: none;

  overflow-x: hidden;
  padding-left: 470px;

  &-left {
    width: 470px; // TODO: auto calc
    padding-top: 470px;

    position: fixed;
    left: calc(var(--full-width) / 2 - #{$extended-width / 2});

    > div {
      margin-bottom: 10px;
    }
  }

  &-right {
    flex: 1;
    min-width: 0;
    margin-left: 60px;
    pointer-events: all;
  }

  &-tile {
    display: block;
    position: relative;
    user-select: none;

    // By default, before enter
    opacity: 0;

    cursor: pointer;

    $outer: &;

    &-meta,
    &-meta-pinned {
      background: $background;
      line-height: 60px;
      font-size: 32px;

      @include font-monospace;
      text-transform: uppercase;

      display: flex;

      &-sharp {
        width: 60px;
        font-weight: 900;
        background: $background;

        color: change-color($primary, $alpha: 0.5);

        font-size: 32px;
        text-align: center;
        font-style: italic;
      }

      &-slug {
        padding-left: 20px;
        font-weight: 900;
        color: $primary;

        font-style: italic;
        flex: 1;
      }

      &-author {
        background: $background;

        @include font-sans;
        color: rgba(0, 0, 0, 0.3);
        padding: 0 20px 0 0;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 105px + $hover-shift; // This is fixed so we can do something fancy

        clip-path: polygon(
          #{20px + $hover-shift} 0%,
          100% 0,
          100% 100%,
          $hover-shift 100%
        );

        transition: clip-path 0.2s ease;

        font-size: 16px;
        line-height: 16px;
        font-weight: 800;

        &:after {
          margin-top: 2px;
          @include font-monospace;
          content: 'AUTHORED';
          line-height: 12px;
          font-size: 12px;
          font-weight: 400;
          font-style: italic;
        }

        @include right-biased;
      }
    }

    &-inner,
    &-inner-pinned {
      background: rgba(0, 0, 0, 0.05);
      border-left: rgba(0, 0, 0, 0.1) 5px solid;
      padding: 20px 25px 25px 25px;

      @mixin inner-section-header {
        @include font-monospace;
        display: block;
        font-size: 14px;
        line-height: 20px; // This number is choosen because meow can do simpler math
        color: rgba(0, 0, 0, 0.38);

        transition: opacity $pin-transition-length ease;
      }

      @mixin firsty-bold {
        &-text::first-letter {
          font-weight: 900;
        }
      }

      &-summary {
        &:before {
          @include inner-section-header;
          content: '::SUMMARY >>';
        }

        @include font-serif;
        @include firsty-bold;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;

        transition: transform $pin-transition-length ease,
          opacity $pin-transition-length ease;
      }

      &-mtime {
        &:before {
          @include inner-section-header;
          content: '::MTIME >>';
        }

        @include firsty-bold;
        @include font-monospace;
        color: $accent;

        font-size: 16px;
        line-height: 20px;

        transition: transform $pin-transition-length ease,
          opacity $pin-transition-length ease;
      }

      position: relative;
      // overflow: hidden;

      /**
       * Right sheet. This class itself is used as an wrapper for the `&-sheet:after`
       * to receive the right bias values
       */
      &-sheet {
        position: absolute;
        pointer-events: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include right-biased;
      }

      &-sheet:after {
        content: 'VIEW DETAIL';
        user-select: none;
        background: change-color($accent, $alpha: 0.1);

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -$hover-shift;

        clip-path: polygon(
          calc(100% - #{105px + $hover-shift}) 0,
          100% 0,
          100% 900px,
          calc(100% - #{105px + 300px + $hover-shift}) 900px
        );

        transition: transform 0.2s ease, opacity 0.2s ease;
        transform: none;
        opacity: 0.5;

        @include font-monospace;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        text-align: center;
        line-height: $hover-shift;

        font-weight: 900;
        color: change-color($accent, $alpha: 0.38);
      }
    }

    &-meta-pinned {
      background: none;
    }

    &-inner-pinned {
      background: none;
      border-left-color: transparent;

      &:after {
        content: none;
      }
    }

    $root: &;
    &:hover {
      #{$root}-meta-author {
        clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0 100%);
      }

      #{$root}-inner-sheet:after {
        transform: translateX(-$hover-shift);
        opacity: 1;
      }
    }
  }
}

.listing-fade {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    transition: opacity 0.5s ease;
    opacity: 1;
  }

  &-enter-done {
    opacity: 1;
  }

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    transition: opacity 0.5s ease;
    opacity: 1;
  }

  &-appear-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    transition: opacity 0.5s ease;
    opacity: 0;
    pointer-events: none;
  }

  &-exit-done {
    opacity: 0;
    pointer-events: none;
  }
}

// Entity setup
.home-entity {
  margin-bottom: 40px;

  $free-inner-left: 420px + 10px + 40px + 60px;
  $pinned-inner-left: 120px + 10px + 40px + 20px;
  // TODO(refactor): extract 1240px
  $full-width: 1240px - $pinned-inner-left;

  &-dummy {
    display: none;
  }

  &-inner {
    width: $full-width;
    transform: translateY(var(--home-pin-vert))
      translateX(calc(var(--home-pin-early) + var(--home-pin-late)));

    @function right-clip-bound() {
      @return calc(
        #{$full-width} + var(--right-bias) - var(--home-pin-early) - var(--home-pin-late)
      );
    }

    clip-path: polygon(
      0 0,
      0 100%,
      right-clip-bound() 100%,
      right-clip-bound() 0
    );
  }

  &-pinned &-inner {
    position: fixed;
    top: 40px;
    left: hoz-padding($pinned-inner-left);
  }

  &-pinned &-dummy {
    display: block;
  }
}

// Entity shift biases
.home-entity {
  --right-bias: #{-$move-dist};

  &-pinned {
    --right-bias: 0px;
  }
}

// Clip mask transitions
.home-pin {
  &-clip {
    transition: clip-path $pin-transition-length ease;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }

  &-anti-clip {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    transition: clip-path $pin-transition-length ease;
    clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
  }
}

.home-entity-pinned .home-pin {
  &-clip {
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
  }

  &-anti-clip {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}

// Content displacements
.home-entity-pinned .home-tile {
  &-inner,
  &-inner-pinned {
    &-summary {
      &::before {
        opacity: 0;
      }

      // 20px padding, 20px header -> 10px padding
      transform: translateY(-30px) translateX(-30px);
      opacity: 0.54;
    }

    &-mtime {
      &::before {
        opacity: 0;
      }

      transform: translateY(-70px) translateX(-30px);
      opacity: 0.54;
    }
  }
}

// Info fold
.home-tile {
  &-meta,
  &-meta-pinned {
    transform-origin: 0 0;
    transition: transform 0.2s ease, opacity 0.2s ease;
  }

  &-inner,
  &-inner-pinned {
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
}

.home-folded .home-entity-pinned {
  .home-tile {
    &-meta,
    &-meta-pinned {
      transform: scale(0.6);
      opacity: 0.7;
    }
    &-inner,
    &-inner-pinned {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
}

// Repo status
.repo-status {
  display: inline-block;
  @include font-monospace;
  height: 48px;
  background: $accent-background;
  align-items: flex-end;

  position: relative;
  padding-left: 48px + 15px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 9px;

  pointer-events: all;
  overflow: hidden;

  &::before {
    content: attr(data-icon);
    font-size: 12px;
    line-height: 48px;
    width: 48px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.38);
    background: $accent-background;

    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::after {
    content: 'goto repo;';
    position: absolute;
    bottom: 9px;
    right: 0;
    left: 48px + 15px;

    font-size: 16px;
    line-height: 18px;

    transform: translateY(20px);
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.2s ease;
  }

  &-slug {
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.7);
  }

  &-rev {
    font-size: 18px;
    line-height: 18px;
    color: $accent;

    transition: opacity 0.2s ease, transform 0.2s ease;
  }

  $root: &;
  &:hover {
    #{$root}-rev {
      opacity: 0;
      transform: translateY(-20px);
    }

    &::after {
      opacity: 1;
      transform: none;
    }
  }

  transform: none;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);

  transition: transform 0.2s ease, clip-path 0.2s ease;
}

@for $i from 1 to 10 {
  :nth-child(#{$i}) > .repo-status {
    transition-delay: ($i - 1) * 0.1s;
  }
}

.main-wrapper-off .repo-status {
  transform: translateX(-20px);
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
}
