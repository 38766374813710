// Colors

$primary: #753217;
$bg-alpha: 0.1;
$background: change-color($primary, $alpha: $bg-alpha);

$accent: #275c99;
$accent-background: change-color($accent, $alpha: $bg-alpha);

// Length

$extended-width: 1240px;
$scrollbar-width: 10px; // Sorry firefox, we did our best
@function hoz-padding($pad, $expected-width: $extended-width) {
  @return calc(50vw - #{$scrollbar-width/2 + $expected-width/2 - $pad});
}
